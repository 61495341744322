@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MurrayHill';
  src: local('MurrayHill'), url(/static/media/murrayhillboldregular.8e4bcca8.ttf) format('truetype');
}
 
/* full calendar classes */
.fc-day:hover {
    background: #e7f8fc!important;
    cursor: pointer;
}

.fc-event-title {
  overflow: hidden!important;
  margin-top: 20px!important;
}

.fc-event {
  overflow: hidden!important;
  background: #FFFFFF!important;
}

.fc-event-today {
  background: #fffadf!important;
}

.fc-event:hover {
  background: #e7f8fc!important;
}

/* Styles for the black rectangle and published text */
.black-rectangle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 0px;
  padding-left: 16px;
  padding-right: 6px;
  border-radius: 4px;
  font-size: 10px;
}

.published-text {
  margin-bottom: 2px;
}

/* Additional styles for the event checkmark */
.fc-event-checkmark {
  width: 16px;
  height: 16px;
  /*background-color: black;*/
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}


/* react quill classes */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before { content: '12px'; font-size: 12px !important; }
.ql-picker-label[data-value="12px"]::before { content: '12px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before { content: '14px'; font-size: 14px !important; }
.ql-picker-label[data-value="14px"]::before { content: '14px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before { content: '18px'; font-size: 18px !important; }
.ql-picker-label[data-value="18px"]::before { content: '18px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before { content: '24px'; font-size: 24px !important; }
.ql-picker-label[data-value="24px"]::before { content: '24px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before { content: '30px'; font-size: 30px !important; }
.ql-picker-label[data-value="30px"]::before { content: '30px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before { content: '36px'; font-size: 36px !important; }
.ql-picker-label[data-value="36px"]::before { content: '36px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before { content: '48px'; font-size: 48px !important; }
.ql-picker-label[data-value="48px"]::before { content: '48px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="60px"]::before { content: '60px'; font-size: 60px !important; }
.ql-picker-label[data-value="60px"]::before { content: '60px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before { content: '72px'; font-size: 72px !important; }
.ql-picker-label[data-value="72px"]::before { content: '72px' !important; }

.openAutoCompleteRow {
  background-color: #FFFFFF!important;
}

.openAutoCompleteRow:hover {
  background-color: #ededed!important;
  cursor: pointer;
}

.MuiTableRow-root:nth-child(2n) {
   background-color: #ededed;
};


.InlineMediaViewer_mediaContainer__2XLsT{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(0,0,0,.8);
    height: 800px;
    border-radius: 5px;
  }
  
  .InlineMediaViewer_content__1N06z{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 80%;
    padding: 4px
  }
  
  .InlineMediaViewer_pagination__XMeEm{
    position: absolute;
    z-index: 2;
    top: 74%;
    color: white;
    background-color: rgba(0,0,0,.8);
    border: 1px solid white;
    padding: 8px;
  }

  .InlineMediaViewer_caption__mdFG8{
    position: absolute;
    z-index: 10;
    top: 98%;
    color: white;
    background-color: rgba(0,0,0,.8);
    border: 1px solid white;
    padding: 8px;
  }
  
  .InlineMediaViewer_fabClose__ZkfWR{
      position: absolute;
      z-index: 2;
      left: 97%;
      bottom: 93%;
  }
  
  .InlineMediaViewer_fabLeft__3oGeF{
    position: absolute;
    z-index: 1;
    right: 92%;
  }
  
  .InlineMediaViewer_fabRight__312Dg{
    position: absolute;
    z-index: 1;
    left: 92%;
  }
  
  .InlineMediaViewer_mediaMain__GfFzP{
    max-width: 100%;
    max-height: calc(100% - 8px);
    transition: opacity .5s ease-in-out;
    position: absolute;
    z-index: 2;
  }
  
  .InlineMediaViewer_mediaMainPrev__3kyDt{
    max-width: 100%;
    max-height: 100%;
    transition: opacity .5s ease-in-out;
    z-index: 2;
  }
  
  .InlineMediaViewer_mediaOpacity1__tO92Q{
    opacity: 1;
  }
  
  .InlineMediaViewer_mediaOpacity0__xOUed{
    opacity: 0;
  }
  
  .InlineMediaViewer_media__3CNwY {
      padding: 4px;
      width: 20%;
      height: 100%;
    }
  
  .InlineMediaViewer_media__3CNwY:hover  {
  
      background-color:#000;
      opacity:0.7;
      cursor: pointer;
      transition: all 0.2s linear;
  }

.InlineMediaViewer_scroll__3RWDp{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 20%;
    padding: 4px;
}
.MediaViewer_mediaContainer__2eXkJ{
  display: flex;
  justify-content: center;
  padding: 32px;
}

.MediaViewer_caption__1hsZ0{
  position: absolute;
  z-index: 10;
  top: 98%;
  color: white;
  background-color: rgba(0,0,0,.8);
  border: 1px solid white;
  padding: 8px;
}

.MediaViewer_content__162hi{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.MediaViewer_pagination__1N-vv{
  position: absolute;
  z-index: 2;
  top: 88%;
  color: white;
  background-color: rgba(0,0,0,.8);
  border: 1px solid white;
  padding: 8px;
}

.MediaViewer_fabClose__y_Ui6{
    position: absolute;
    z-index: 1000;
    left: 97%;
    bottom: 93%;
}

.MediaViewer_fabLeft__2Ov1q{
  position: absolute;
  z-index: 1;
  right: 97%;
}

.MediaViewer_fabRight__36XpQ{
  position: absolute;
  z-index: 1;
  left: 97%;
}

.MediaViewer_mediaMain__KNIhT{
  max-width: 100%;
  max-height: 600px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  z-index: 2;
}

.MediaViewer_mediaMainPrev__2-M57{
  max-width: 100%;
  max-height: 600px;
  transition: opacity .5s ease-in-out;
  z-index: 2;
}

.MediaViewer_mediaOpacity1__2GaY6{
  opacity: 1;
}

.MediaViewer_mediaOpacity0__1D0qE{
  opacity: 0;
}

.MediaViewer_media__1e9QY {
    padding: 4px;
    width: 200px;
    height: 200px;
  }

.MediaViewer_media__1e9QY:hover  {

    background-color:#000;
    opacity:0.7;
    cursor: pointer;
    transition: all 0.2s linear;
}
.Modal_Modal__Et3WG {
    position: fixed;
    z-index: 500;
    background-color: #252525;
    width: 70%;
    
    border-radius: 8px;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 5%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__Et3WG {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Backdrop_Backdrop__2-QIz {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.90);
}
.LoadingSpinner_ldsRing__hpKgU {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.LoadingSpinner_ldsRing__hpKgU div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    -webkit-animation: LoadingSpinner_ldsRing__hpKgU 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: LoadingSpinner_ldsRing__hpKgU 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
}
.LoadingSpinner_ldsRing__hpKgU div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}
.LoadingSpinner_ldsRing__hpKgU div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.LoadingSpinner_ldsRing__hpKgU div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}
@-webkit-keyframes LoadingSpinner_ldsRing__hpKgU {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes LoadingSpinner_ldsRing__hpKgU {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.ProductPreview_ButtonGroup__3WZtX button{
    
    border-radius: 16px;
    margin: 0px 8px;

}
.ProductPreview_ButtonGroup__3WZtX{
    display:flex;
    flex-direction: row;
    flex-flow: row;
}

.ProductPreview_CardInfo__2jX8e{
    display:flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: left;
    width: 100%;
}

.ProductPreview_Column__13wc_{
    display:flex;
    justify-content: center;
    align-items: top;
    width: 100%;
}

.ProductPreview_Container__QEC2o{
    height: 100%;
    max-width: 1200px;
    padding:32px;
    background-color: #ffffff;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
}

.ProductPreview_Description__1ugun{
    white-space: pre-wrap;
}

.ProductPreview_ProductName__1GmSZ{
    font-size: 2em;
    padding-top: 32px;
    padding-bottom: 8px;
}

.ProductPreview_QtyInput__2dLKw {
    width:200px;
}   

.ProductPreview_QtyInput__2dLKw input{
    color:#000;
    font-size: 2em;
    text-align:center;
}

.ProductPreview_TypeLabel__2IvvZ{
    text-transform: capitalize;
    padding: 8px 0px;
}
.ProductPreview_VariantSelection__2Wx4a{
    padding: 32px 0px;

}

.ProductPreview_Price__5wbo6 {
    font-size: 1.5em;
    padding: 16px 0px;
}

.ProductPreview_ProductImage__3H-Wv{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-right:32px;
}

@media (max-width: 600px) {

    .ProductPreview_ButtonGroup__3WZtX{
        display:grid;
        grid-template-columns: repeat(2, 50%);
    }

    .ProductPreview_Container__QEC2o{
        flex-direction: column;
    }

    .ProductPreview_Column__13wc_{
        display:flex;
    }

    .ProductPreview_ProductImage__3H-Wv{
        padding-right:0px;
        margin-top:0px;
        padding-top:0px;
        padding-bottom:0px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ProductPreview_Column__13wc_ img{
        padding: 0px;
        height: 250px;
        width: 100%;
        display: block;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        margin: auto;
    }

    .ProductPreview_QtyInput__2dLKw {
        max-width: 256px;
    }


}

@media (max-width: 600px) {
    .ProductPreview_ButtonGroup__3WZtX{
        display:grid;
        grid-template-columns: initial;
    }

    .ProductPreview_QtyInput__2dLKw {
        width:100%;
    }
    .ProductPreview_ProductImage__3H-Wv{
        padding-right:0px;
        margin-top:0px;
        padding-top:0px;
        padding-bottom:0px;
        position: relative;
    }
    .ProductPreview_Container__QEC2o{
        height: 100%;
        max-width: 1200px;
        padding:22px;
        padding-top:4px;
        padding-bottom:1px;
        background-color: #ffffff;
        display: flex;
        border-radius: 8px;
        justify-content: space-between;
    }
    .ProductPreview_ProductName__1GmSZ{
        font-size: 1.4em;
        padding-top: 10px;
        padding-bottom: 2px;
    }
    .ProductPreview_Description__1ugun {
        font-size: 14px;
    }
}
.SignIn_ErrorMsg__3Obnw {
    color:red;
    width: 100%;
    text-align: center;
}

.SignIn_Container__37WjC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignIn_Container__37WjC form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignIn_Container__37WjC h1{
    font-family: 'Pacifico', cursive;
    text-align: center;
    color: rgb(255, 193, 7);
}

.SignIn_Logo__eHq5s{
    max-width: 50%;
    padding: 64px;

}
